import * as React from "react";
import Layout from "../components/Layout";
import Checkout from "../components/Checkout";

import "@fontsource/dm-sans";
import "./checkout.sass";

// markup
const checkout = (props) => {
  let subtitle, title, text, checkboxes, price, prest, cid;
  if (props.location.state != null) {
    subtitle = props.location.state.subtitle;
    title = props.location.state.title;
    text = props.location.state.text;
    checkboxes = props.location.state.checkboxes;
    price = props.location.state.price;
    prest = props.location.state.prest;
    cid = props.location.state.cid;

    localStorage.setItem("subtitle", props.location.state.subtitle);
    localStorage.setItem("title", props.location.state.title);
    localStorage.setItem("text", props.location.state.text);
    localStorage.setItem(
      "checkboxes",
      props.location.state.checkboxes.join("-")
    );
    localStorage.setItem("price", props.location.state.price);
    localStorage.setItem("prest", props.location.state.prest);
    localStorage.setItem("cid", props.location.state.cid);
    // useful while building
  } else {
    subtitle = "subtitle";
    title = "title";
    text = "text";
    checkboxes = ["hello", "bye"];
    price = "price";
    prest = "prest";
    cid = "cid";

    if (typeof window !== "undefined" && window.localStorage) {
      subtitle =
        localStorage.getItem("subtitle") === null
          ? "hello"
          : localStorage.getItem("subtitle");

      title =
        localStorage.getItem("title") === null
          ? "hello"
          : localStorage.getItem("title");

      text =
        localStorage.getItem("text") === null
          ? "hello"
          : localStorage.getItem("text");

      checkboxes =
        localStorage.getItem("checkboxes") === null
          ? "hello"
          : localStorage.getItem("checkboxes").split("-");

      price =
        localStorage.getItem("price") === null
          ? "hello"
          : localStorage.getItem("price");

      prest =
        localStorage.getItem("prest") === null
          ? "hello"
          : localStorage.getItem("prest");

      cid =
        localStorage.getItem("cid") === null
          ? "hello"
          : localStorage.getItem("cid");
    }
  }

  return (
    <Layout>
      <div className="background-color-div">
        <div id="header-box">
          <div id="left">
            <h5>{subtitle}</h5>
            <h2>{title}</h2>
            <p id="descr">{text}</p>
          </div>
          <div id="right">
            <ul>
              {checkboxes.map((c) => (
                <li>{c}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className="checkout-row">
          <Checkout prest={prest} price={price} title={title} cid={cid} />
        </div>
      </div>
    </Layout>
  );
};

export default checkout;
