import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./Checkout.sass";
import Switch from "react-switch";
import Lightbox from "react-datatrans-light-box";
import { navigate, Link } from "gatsby";
import arrow from "../images/arrow_up.png";

class Checkout extends React.Component {
  constructor(props) {
    let today = new Date();
    let year = today.getFullYear();
    super(props);
    this.state = {
      step: 0,
      checked: false,
      name: "",
      mail: "",
      mailConfirmation: "",
      phone: "",
      addresse: "",
      time: "",
      date: "",
      comment: "",
      showLightboxOverlay: false,
      prestationId: "",
      associates: "",
      capital: "",
      canton: "",
      startDate:
        today.getDate() + "." + ("0" + (today.getMonth() + 1)) + "." + year,
      slotsTaken: [],
      slots: [],
      selectedTile: -1,
      dateObj: null,
      prompt: "",
      tc: false,
      meetingType: "none",
      price: props.price,
      highlight: false,
      successUrl: "https://google.com",
    };
    this.nextStep = this.nextStep.bind(this);
    this.lastStep = this.lastStep.bind(this);
    this.clientSwitch = this.clientSwitch.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleMeetingChange = this.handleMeetingChange.bind(this);
    // conract id passed down, useful when sending contract e-mail link
    this.cid = props.cid;
  }

  // prints to the prompt
  printPrompt(text) {
    this.setState({
      prompt: text,
      highlight: true,
    });
    setTimeout(() => {
      this.setState({
        prompt: "",
        highlight: false,
      });
    }, 3000);
  }

  // validation function
  validate() {
    // first step validation
    const genString = "Veuillez saisir tous les champs obligatoires (*)";
    if (this.state.step === 0) {
      // missing name field
      if (this.state.name == "") {
        if (this.state.checked) {
          this.printPrompt(genString);
        } else {
          this.printPrompt(genString);
        }
        return false;
      }

      // missing name mail
      if (this.state.mail == "") {
        this.printPrompt(genString);
        return false;
      }

      // missing confirmation mail
      if (this.state.mailConfirmation == "") {
        this.printPrompt(genString);
        return false;
      }

      // not matching mails
      if (this.state.mailConfirmation !== this.state.mail) {
        this.printPrompt("L'e-mail de confirmation est incorrect.");
        return false;
      }

      // validate mail addresse format
      if (
        !this.state.mail.includes("@") ||
        !this.state.mail.split("@")[1].includes(".")
      ) {
        this.printPrompt("Veuillez saisir une adresse mail valide");
        return false;
      }

      // missing phone number
      if (this.state.phone == "") {
        this.printPrompt(genString);
        return false;
      }

      if (
        this.props.prest === "meeting" &&
        !this.props.title.includes("Entretien") &&
        this.state.meetingType == "none"
      ) {
        this.printPrompt(genString);
        return false;
      }

      return true;
    }
    // second step validation
    if (this.state.step === 1) {
      if (this.props.prest === "meeting") {
        // missing date
        if (this.state.dateObj == null) {
          this.printPrompt("Veuillez séléctionner une date");
          return false;
        }
        // missing name mail
        if (this.state.selectedTile == -1) {
          this.printPrompt("Veuillez séléctionner une heure");
          return false;
        }
        return true;
      }

      if (this.props.prest === "contract") {
        // only payment, no second form
        return true;
      }

      if (this.props.prest === "abo") {
        // missing start date
        if (this.state.startDate == "") {
          this.printPrompt("Veuillez séléctionner une date");
          return false;
        }
        return true;
      }

      // second step
      if (this.props.prest === "societe") {
        // missing associates
        if (this.state.associates == "") {
          this.printPrompt(genString);
          return false;
        }

        // missing social capital
        if (this.state.capital == "") {
          this.printPrompt(genString);
          return false;
        }

        // missing canton
        if (this.state.canton == "") {
          this.printPrompt(genString);
          return false;
        }
        return true;
      }
    }

    if (this.state.step === 2) {
      // missing checked box
      if (this.state.tc == false) {
        this.printPrompt("Veuillez accepter nos conditions générales");
        return false;
      }
      return true;
    }
    return true;
  }

  // called to get checkout
  getCheckoutBundle() {
    let checkout = {};
    checkout.name = this.state.name;
    checkout.email = this.state.mail;
    checkout.phone = this.state.phone;
    checkout.isBusiness = !this.state.checked;
    checkout.adresse = this.state.addresse;
    checkout.comment = this.state.comment;
    console.log("HELLOA");
    console.log(this.state.price);
    console.log(this.props.prest);
    console.log(this.props.price);
    checkout.price =
      this.props.prest === "societe"
        ? this.getSocietePrice()
        : this.props.price;
    checkout.title = this.props.title;

    if (this.props.prest === "meeting") {
      //FOR ALL MEETINGS WE GET PRICE FROM PAREWNT COMPONENT, BUT SOME BRANCH INTO EITHER MEETINGS OR CALLS, SO ADDED SPECIAL CASE WHERE PRICE IS PULLED FROM THIS (CHECKOU COMPONENTS) STATE
      if(this.props.price === "-"){
        checkout.price = this.state.price;
      }
      // dangerous, better to check on other field
      checkout.type = checkout.price == 100 ? "call" : "meet";
      checkout.time = this.state.time;
      let date = new Date(this.state.dateObj);
      date.setSeconds(0);
      date.setMilliseconds(0);
      checkout.date = date;
    }

    if (this.props.prest === "contract") {
      checkout.type = "contract";
      checkout.cid = this.cid;
    }

    if (this.props.prest === "abo") {
      checkout.type = "abo";
      checkout.aboStart = this.state.startDate;
    }

    if (this.props.prest === "societe") {
      checkout.type = "company";
      checkout.associates = this.state.associates;
      checkout.capital = this.state.capital;
      checkout.canton = this.state.canton;
    }
    return checkout;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.price !== this.props.price) {
      this.setState({
        price: this.props.price,
        slots: [],
        selectedTile: -1,
        step: 0,
        date: "",
        time: "",
        dateObj: null,
      });
    }
  }

  async componentDidMount() {
    // navigate("/payment-success", {
    //   state: { type: "call", date: this.state.dateObj, phone: this.state.phone, email: this.state.email, prestationName: this.props.title },
    // })

    window.addEventListener("message", (event) => {
      if (event.data === "close_iFrame") {
        this.setState({
          showLightboxOverlay: false,
        });
        navigate("/payment-success", {
          state: {
            type: this.getCheckoutBundle().type,
            date: this.state.dateObj,
            phone: this.state.phone,
            email: this.state.mail,
            prestationName: this.props.title,
            aboStart:
              this.props.prest === "abo"
                ? this.getCheckoutBundle().aboStart
                : {},
            meta:
              this.props.prest === "societe"
                ? {
                    associates: this.getCheckoutBundle().associates,
                    capital: this.getCheckoutBundle().capital,
                    canton: this.getCheckoutBundle().canton,
                  }
                : {},
          },
        });
      }
    });
    try {
      const request = {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      let response = await fetch(
        "https://legalify-back.herokuapp.com" + "/slots", //"https://legalify-back.herokuapp.com" "http://localhost:3001"
        request
      );
      let jsonResponse = await response.json();
      if (jsonResponse.success) {
        let slotsTaken = jsonResponse.data;
        //CHECK LATER
        this.setState({
          slotsTaken: slotsTaken,
        });
      } else {
        alert("Failed to get free slots");
        return;
      }
    } catch (err) {
      return;
    }
  }

  // increments the step count
  async nextStep(event) {
    // call validation function on every next step
    console.log("here");

    if (!this.validate()) {
      return;
    }

    if (this.props.prest === "devis") {
      let checkout = {};
      checkout.email = this.state.mail;
      checkout.prestationName = this.props.title;
      checkout.name = this.state.name;
      checkout.phone = this.state.phone;
      checkout.address = this.state.addresse;
      checkout.comment = this.state.comment;
      console.log(checkout);
      try {
        const request = {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },

          body: JSON.stringify(checkout),
        };
        let response = await fetch(
          "https://legalify-back.herokuapp.com" + "/free", //"https://legalify-back.herokuapp.com" "http://localhost:3001"
          request
        );
        let jsonResponse = await response.json();
        if (jsonResponse.success) {
          console.log(jsonResponse);
          this.setState((prevState) => ({
            step: this.state.step + 3,
          }));
          return;
        } else {
          console.log(jsonResponse);
          return;
        }
      } catch (err) {
        return;
      }
    }

    // if last step, call the API
    if (this.state.step === 2) {
      let body = this.getCheckoutBundle();
      body.successUrl = this.returnSuccessUrl();
      try {
        const request = {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },

          body: JSON.stringify(body),
        };
        let response = await fetch(
          "https://legalify-back.herokuapp.com" + "/prestation", //"https://legalify-back.herokuapp.com" "http://localhost:3001"
          request
        );
        let jsonResponse = await response.json();
        if (jsonResponse.success) {
          this.setState({
            prestationId: jsonResponse.data.prestationId,
            showLightboxOverlay: true,
          });
          return;
        } else {
          console.log(body);
          return;
        }
      } catch (err) {
        return;
      }
    }

    if (this.state.step < 2) {
      if (this.props.prest === "contract") {
        this.setState((prevState) => ({
          step: this.state.step + 2,
        }));
      } else {
        this.setState((prevState) => ({
          step: this.state.step + 1,
        }));
      }
    }
  }

  // decrements the step count
  lastStep(event) {
    if (this.props.prest === "contract") {
      if (this.state.step > 0) {
        this.setState((prevState) => ({
          step: this.state.step - 2,
        }));
      }
    } else {
      if (this.state.step > 0) {
        this.setState((prevState) => ({
          step: this.state.step - 1,
          date: "",
          slots: [],
          dateObj: null,
        }));
      }
    }
  }

  clientSwitch(checked) {
    this.setState({ checked });
  }

  showOverlay() {
    this.setState({ showLightboxOverlay: !this.state.showLightboxOverlay });
  }

  setDateTime = (tile, hours, minutes, available) => {
    if (!available) {
      return;
    }
    let date = new Date(this.state.dateObj);
    date.setHours(hours);
    date.setMinutes(minutes);
    this.setState({
      dateObj: date,
      selectedTile: tile, // default is unselected
    });
  };

  //args as h-mins format eg: 14-30, 6-0
  setAppointmentsTimes = (startTime, endTime) => {
    if (this.state.slotsTaken.length === 0) {
      return;
    }
    let startHrsMins = startTime.split("-");
    let endHrsMins = endTime.split("-");
    let timeStart = new Date(this.state.dateObj);

    timeStart.setHours(parseInt(startHrsMins[0]));
    timeStart.setMinutes(parseInt(startHrsMins[1]));
    timeStart.setSeconds(0);
    timeStart.setMilliseconds(0);

    let timeEnd = new Date(this.state.dateObj);
    timeEnd.setHours(parseInt(endHrsMins[0]));
    timeEnd.setMinutes(parseInt(endHrsMins[1]));
    timeEnd.setSeconds(0);
    timeEnd.setMilliseconds(0);

    let slots = [];
    let now = new Date();

    //set pool n+1 : morning -> 9-14, afternoon -> 14-9
    let endingNextPool = new Date();
    if (now.getHours() < 14 && now.getHours() > 9) {
      endingNextPool.setDate(now.getDate() + 1);
      endingNextPool.setHours(9);
      endingNextPool.setMinutes(0);
      endingNextPool.setSeconds(0);
      endingNextPool.setMilliseconds(0);
    } else {
      if (now.getHours() > 9) {
        endingNextPool.setDate(now.getDate() + 1);
      }
      endingNextPool.setHours(14);
      endingNextPool.setMinutes(0);
      endingNextPool.setSeconds(0);
      endingNextPool.setMilliseconds(0);
    }
    for (let i = 1; timeStart < timeEnd; i++) {
      let newSlot = {
        date: new Date(timeStart.getTime()),
        available: true,
        count: 0,
      };
      this.state.slotsTaken.forEach((slot) => {
        let slotDate = new Date(slot);

        if (newSlot.date.getTime() === slotDate.getTime()) {
          if (newSlot.count > 0) {
            newSlot.available = false;
            newSlot.count = 2;
          } else {
            newSlot.count = newSlot.count + 1;
          }
        }
        if (newSlot.date.getTime() < endingNextPool.getTime()) {
          newSlot.available = false;
          newSlot.count = 2;
        }
      });
      slots.push(newSlot);
      timeStart = new Date(timeStart.getTime() + 30 * 60000);
    }

    if (this.state.price === 150) {
      for (let i = 0; i < slots.length; i++) {
        if (
          (i + 1 < slots.length &&
            !slots[i + 1].available &&
            slots[i].available) ||
          (slots[i].date.getHours() === 18 && slots[i].date.getMinutes() === 30)
        ) {
          slots[i].available = false;
        }
      }
    }

    this.setState({
      slots: slots,
    });
  };

  returnFullDateFr = () => {
    if (this.state.dateObj === null) {
      return "";
    }
    let selectedDate = new Date(this.state.date);
    let str1 = selectedDate.toLocaleDateString("fr-FR", { weekday: "long" });
    let str2 = this.state.dateObj.getDate();
    let str3 = selectedDate.toLocaleString("fr-FR", { month: "long" });
    let str4 = this.state.dateObj.getFullYear();
    return (
      str1[0].toUpperCase() +
      str1.substring(1) +
      " " +
      str2 +
      " " +
      str3 +
      " " +
      str4
    );
  };

  renderSlotTiles = () => {
    if (this.state.slots.length === 0) {
      return "";
    } else {
      return (
        <div>
          <div id="date-fr">{this.returnFullDateFr()}</div>
          <div id="tiles-container">
            {this.state.slots.map((slot, index) => {
              let slotClass = !slot.available ? "slot" : "slot slot-available";
              return (
                <div
                  className={
                    this.state.selectedTile === index
                      ? slotClass + " slot-selected"
                      : slotClass
                  }
                  onClick={() => {
                    this.setDateTime(
                      index,
                      slot.date.getHours(),
                      slot.date.getMinutes(),
                      slot.available
                    );
                  }}
                >
                  {slot.date.getHours() +
                    ":" +
                    slot.date.getMinutes() +
                    (index % 2 === 0 ? "0" : "")}
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  };

  parseIntoDate = (dateString) => {
    let separStrings = dateString.split("-");
    separStrings.map((str, i) => {
      if (str.charAt(0) === "0") {
        separStrings[i] = str.replace("0", "");
      }
    });
    let date = new Date(
      parseInt(separStrings[0]),
      parseInt(separStrings[1]) - 1,
      parseInt(separStrings[2])
    );

    this.setState({
      dateObj: date,
    });
  };

  renderDateDescFr = () => {
    if (
      this.state.dateObj !== null &&
      typeof this.state.dateObj.getMonth === "function"
    ) {
      return <div>{this.renderSlotTiles()}</div>;
    } else {
      return <div></div>;
    }
  };

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (target.name === "date") {
      this.parseIntoDate(target.value);
      setTimeout(() => {
        this.setAppointmentsTimes("9-0", "19-0");
      }, 100);
      this.setState({
        date: target.value,
        selectedTile: -1,
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  }

  handleMeetingChange(event) {
    const target = event.target;
    const value = target.value;
    this.setState({
      meetingType: value,
      price: value == "none" ? "-" : value == "pers" ? 150 : 100,
    });
  }

  // returns second step title depending on the type of prestation
  // note: meeting, contract, abo, societe
  getSecondTitle() {
    if (this.props.prest === "meeting") {
      return "Date et heure";
    }

    if (this.props.prest === "contract") {
      return "Contract";
    }

    if (this.props.prest === "abo") {
      return "Date de début";
    }

    if (this.props.prest === "societe") {
      return "Société";
    }

    if (this.props.prest === "devis") {
      return "Merci de votre demande";
    }

    return "Default";
  }

  // renders the progress bar
  progressBar() {
    if (this.props.prest === "devis") {
      return "";
    }

    return (
      <div
        id="progress-bar"
        className={() => (this.state.step === 3 ? "hidden" : "")}
      >
        <div className="step-holder active-step">
          <div className="step-number">1</div>
          <div className="step-description">Informations</div>
        </div>
        {this.props.prest === "contract" ? (
          ""
        ) : (
          <div
            className={
              this.state.step > 0 ? "step-holder active-step" : "step-holder"
            }
          >
            <div className="step-number">2</div>
            <div className="step-description">{this.getSecondTitle()}</div>
          </div>
        )}
        <div
          className={
            this.state.step > 1 ? "step-holder active-step" : "step-holder"
          }
        >
          <div className="step-number">
            {this.props.prest === "contract" ? "2" : "3"}
          </div>
          <div className="step-description">Paiement</div>
        </div>
      </div>
    );
  }

  // renders the payment confirmation view
  paymentConfirmation() {
    if (this.state.step === 3) {
      return (
        <div id="payment-confirmation">
          <div id="prompt">
            <h3>Confirmation</h3>
            <p>
              Votre demande pour l’obtention d’un devis ({this.props.title})
              nous est bien parvenue. Nous vous en remercions. Nous vous
              contacterons prochainement pour répondre à votre demande.
              <br />
              <br />
              En cas de questions supplémentaires, merci de nous contacter par
              e-mail à info@legalify.ch ou de nous appeler au +41 22 731 40 31.
              <br /> <br />
              Bonne journée et à bientôt.
              <br /> <br />
              L’équipe Legalify
            </p>
          </div>
        </div>
      );
    }
  }

  // renders client information step
  clientInformation() {
    if (this.state.step === 0) {
      return (
        <div id="client-info">
          <div id="switcher">
            <span className="left">Société</span>
            <Switch
              onChange={this.clientSwitch}
              checked={this.state.checked}
              uncheckedIcon={false}
              checkedIcon={false}
              onColor="#1F1F1F"
              height={20}
              width={38}
            />
            <span className="right">Particulier</span>
          </div>
          <div id="form-holder-private">
            <div id="left">
              <label>
                {this.state.checked ? "Nom/Prénom*" : "Raison Sociale*"}
                <input
                  className={
                    this.state.highlight && this.state.name.length == 0
                      ? "red"
                      : ""
                  }
                  type="text"
                  name="name"
                  value={this.state.name}
                  onChange={this.handleInputChange}
                ></input>
              </label>
              <label>
                E-mail*
                <input
                  type="text"
                  name="mail"
                  value={this.state.mail}
                  onChange={this.handleInputChange}
                  className={
                    this.state.highlight && this.state.mail.length == 0
                      ? "red"
                      : ""
                  }
                ></input>
              </label>
              <label>
                Confirmer e-mail*
                <input
                  type="text"
                  name="mailConfirmation"
                  value={this.state.mailConfirmation}
                  onChange={this.handleInputChange}
                  className={
                    this.state.highlight &&
                    this.state.mailConfirmation.length == 0
                      ? "red"
                      : ""
                  }
                ></input>
              </label>
            </div>
            <div id="right">
              <label>
                Téléphone*
                <input
                  type="text"
                  name="phone"
                  value={this.state.phone}
                  onChange={this.handleInputChange}
                  className={
                    this.state.highlight && this.state.phone.length == 0
                      ? "red"
                      : ""
                  }
                ></input>
              </label>
              <label>
                Adresse
                <input
                  type="text"
                  name="addresse"
                  value={this.state.addresse}
                  onChange={this.handleInputChange}
                ></input>
              </label>
              {this.props.prest === "meeting" &&
              !this.props.title.includes("Entretien") ? (
                <label>
                  Type d'entretien*
                  <select
                    id="start-date"
                    name="entretien"
                    onChange={this.handleMeetingChange}
                    value={this.state.meetingType}
                    className={
                      this.state.highlight && this.state.meetingType == "none"
                        ? "red"
                        : ""
                    }
                  >
                    <option value="none">Choisissez</option>
                    <option value="tel">Téléphonique (CHF 100.-)</option>
                    <option value="pers">En personne (CHF 150.-)</option>
                  </select>
                </label>
              ) : (
                ""
              )}
              {this.props.prest === "devis" ? (
                <label>
                  Commentaire
                  <textarea
                    name="comment"
                    value={this.state.comment}
                    onChange={this.handleInputChange}
                    placeholder="Expliquez-nous votre besoin en quelques lignes."
                  ></textarea>
                </label>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      );
    }
  }

  specMeeting() {
    return (
      <div id="prestation-info">
        <div id="date-n-time-picker-container">
          <label id="date-picker-container">
            Choisissez la date de votre entretien.
            <input
              type="date"
              name="date"
              value={this.state.date}
              onChange={this.handleInputChange}
              className="mini"
            ></input>
          </label>
          {this.renderDateDescFr()}
        </div>
        <label>
          Description
          <textarea
            name="comment"
            value={this.state.comment}
            onChange={this.handleInputChange}
            placeholder="Expliquez-nous votre problème en quelques lignes."
          ></textarea>
        </label>
      </div>
    );
  }

  specContract() {
    return <h1>Contract</h1>;
  }

  specAbo() {
    let today = new Date();
    let year = today.getFullYear();
    const aujd =
      today.getDate() + "." + ("0" + (today.getMonth() + 1)) + "." + year;
    const oneMonth = "01." + ("0" + (today.getMonth() + 2)) + "." + year;
    const twoMonth = "01." + ("0" + (today.getMonth() + 3)) + "." + year;

    return (
      <div id="client-info">
        <div id="form-holder-private">
          <div id="left">
            <label>
              Date de début de l'abonnement
              <select
                id="start-date"
                name="startDate"
                onChange={this.handleInputChange}
                value={this.state.startDate}
              >
                <option value={aujd}>{"Aujourd'hui-" + aujd}</option>;
                <option value={oneMonth}>{oneMonth}</option>;
                <option value={twoMonth}>{twoMonth}</option>;
              </select>
            </label>
          </div>
          <div id="right"></div>
        </div>
      </div>
    );
  }

  specSociete() {
    const capitalSarl = (
      <label>
        Capital de la société (CHF)*
        <select
          id="start-date"
          name="capital"
          onChange={this.handleInputChange}
          className={
            this.state.highlight && this.state.capital.length == 0 ? "red" : ""
          }
        >
          <option value="">Choisissez</option>
          <option value="20000">20'000</option>
          <option value="25000">25'000</option>
          <option value="30000">30'000</option>
          <option value="35000">35'000</option>
          <option value="40000">40'000</option>
          <option value="45000">45'000</option>
          <option value="50000">50'000</option>
          <option value="55000">55'000</option>
          <option value="60000">60'000</option>
          <option value="65000">65'000</option>
          <option value="70000">70'000</option>
          <option value="75000">75'000</option>
          <option value="80000">80'000</option>
          <option value="85000">85'000</option>
          <option value="90000">90'000</option>
          <option value="95000">95'000</option>
          <option value="100000">100'000</option>
        </select>
      </label>
    );
    const capitalSA = (
      <label>
        Capital de la société (CHF)*
        <select
          id="start-date"
          name="capital"
          onChange={this.handleInputChange}
          className={
            this.state.highlight && this.state.capital.length == 0 ? "red" : ""
          }
        >
          <option value="">Choisissez</option>
          <option value="100000">100'000</option>
          <option value="105000">105'000</option>
          <option value="110000">110'000</option>
          <option value="115000">115'000</option>
          <option value="120000">120'000</option>
          <option value="125000">125'000</option>
          <option value="130000">130'000</option>
          <option value="135000">135'000</option>
          <option value="140000">140'000</option>
          <option value="145000">145'000</option>
          <option value="150000">150'000</option>
          <option value="155000">155'000</option>
          <option value="160000">160'000</option>
          <option value="165000">165'000</option>
          <option value="170000">170'000</option>
          <option value="175000">175'000</option>
          <option value="180000">180'000</option>
          <option value="185000">185'000</option>
          <option value="190000">190'000</option>
          <option value="195000">195'000</option>
          <option value="200000">200'000</option>
          <option value="250000">250'000</option>
          <option value="300000">300'000</option>
          <option value="350000">350'000</option>
          <option value="400000">400'000</option>
          <option value="450000">450'000</option>
          <option value="500000">500'000</option>
        </select>
      </label>
    );
    return (
      <div id="client-info">
        <div id="form-holder-private">
          <div id="left">
            <label>
              Nombres d'associés*
              <select
                id="associates"
                name="associates"
                onChange={this.handleInputChange}
                className={
                  this.state.highlight && this.state.associates.length == 0
                    ? "red"
                    : ""
                }
              >
                <option value="">Choisissez</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
              </select>
            </label>
            {this.getSocietePrest() === "sa" ? capitalSA : ""}
            {this.getSocietePrest() === "sarl" ? capitalSarl : ""}
          </div>
          <div id="right">
            <label>
              Canton*
              <select
                id="start-date"
                name="canton"
                onChange={this.handleInputChange}
                className={
                  this.state.highlight && this.state.canton.length == 0
                    ? "red"
                    : ""
                }
              >
                <option value="">Choisissez</option>
                <option value="Genève">Genève</option>
                <option value="Vaud">Vaud</option>
                <option value="Fribourg">Fribourg</option>
                <option value="Neuchâtel">Neuchâtel</option>
                <option value="Valais">Valais</option>
              </select>
            </label>
          </div>
        </div>
      </div>
    );
  }

  // uses title to determine type of prestation
  getSocietePrest() {
    const title = this.props.title;
    if (title.includes("limitée")) {
      return "sarl";
    }

    if (title.includes("anonyme")) {
      return "sa";
    }

    if (title.includes("individuelle")) {
      return "indi";
    }
  }

  // calculates the dynamic price for societe prestations
  getSocietePrice() {
    // Sarl pricing
    if (this.getSocietePrest() === "sarl") {
      if (this.state.capital && this.state.associates) {
        const emolument = (capital) => {
          if (capital === "20000") {
            return 275;
          }
          if (capital === "25000") {
            return 300;
          }
          if (capital === "30000") {
            return 325;
          }
          if (capital === "35000") {
            return 350;
          }
          if (capital === "40000") {
            return 375;
          }
          if (capital === "45000") {
            return 400;
          }
          if (capital === "50000") {
            return 425;
          }
          if (capital === "55000") {
            return 450;
          }
          if (capital === "60000") {
            return 475;
          }
          if (capital === "65000") {
            return 500;
          }
          if (capital === "70000") {
            return 525;
          }
          if (capital === "75000") {
            return 550;
          }
          if (capital === "80000") {
            return 575;
          }
          if (capital === "85000") {
            return 600;
          }
          if (capital === "90000") {
            return 625;
          }
          if (capital === "95000") {
            return 650;
          }
          if (capital === "100000") {
            return 675;
          }
        };
        const partners = (partners) => {
          if (partners === "1") {
            return 120;
          }
          if (partners === "2") {
            return 171;
          }
          if (partners === "3") {
            return 222;
          }
          if (partners === "4") {
            return 273;
          }
        };
        const fixed = 250 + 55 + 200;
        return parseInt(
          (fixed +
            partners(this.state.associates) +
            emolument(this.state.capital)) *
            1.077
        );
      } else {
        return "-";
      }
    }

    // SA
    if (this.getSocietePrest() === "sa") {
      if (this.state.capital && this.state.associates) {
        const emolument = (capital) => {
          if (capital === "100000") {
            return 675;
          }
          if (capital === "105000") {
            return 700;
          }
          if (capital === "110000") {
            return 725;
          }
          if (capital === "115000") {
            return 750;
          }
          if (capital === "120000") {
            return 775;
          }
          if (capital === "125000") {
            return 800;
          }
          if (capital === "130000") {
            return 825;
          }
          if (capital === "135000") {
            return 850;
          }
          if (capital === "140000") {
            return 875;
          }
          if (capital === "145000") {
            return 900;
          }
          if (capital === "150000") {
            return 925;
          }
          if (capital === "155000") {
            return 950;
          }
          if (capital === "160000") {
            return 975;
          }
          if (capital === "165000") {
            return 1000;
          }
          if (capital === "170000") {
            return 1025;
          }
          if (capital === "175000") {
            return 1050;
          }
          if (capital === "180000") {
            return 1075;
          }
          if (capital === "185000") {
            return 1100;
          }
          if (capital === "190000") {
            return 1125;
          }
          if (capital === "195000") {
            return 1150;
          }
          if (capital === "200000") {
            return 1175;
          }
          if (capital === "250000") {
            return 1425;
          }
          if (capital === "300000") {
            return 1675;
          }
          if (capital === "350000") {
            return 1925;
          }
          if (capital === "400000") {
            return 2175;
          }
          if (capital === "450000") {
            return 2425;
          }
          if (capital === "500000") {
            return 2675;
          }
        };
        const partners = (partners) => {
          if (partners === "1") {
            return 120;
          }
          if (partners === "2") {
            return 171;
          }
          if (partners === "3") {
            return 222;
          }
          if (partners === "4") {
            return 273;
          }
        };
        const fixed = 250 + 55 + 200;
        return parseInt(
          (fixed +
            partners(this.state.associates) +
            emolument(this.state.capital)) *
            1.077
        );
      } else {
        return "-";
      }
    }
  }

  // renders special information step
  specInformation() {
    if (this.state.step === 1) {
      if (this.props.prest === "meeting") {
        return this.specMeeting();
      }

      if (this.props.prest === "contract") {
        return this.specContract();
      }

      if (this.props.prest === "abo") {
        return this.specAbo();
      }

      if (this.props.prest === "societe") {
        return this.specSociete();
      }
    }
  }

  // renders payment information step
  paymentInformation() {
    // Didi: add payment widget here, to freeze page on payment step, set state to step=2
    if (this.state.step === 2) {
      return (
        <div
          id="payment-info"
          className={this.state.step === 2 ? "" : "hidden"}
        >
          <input
            type="checkbox"
            id="terms"
            name="tc"
            checked={this.state.tc}
            onChange={this.handleInputChange}
          ></input>
          <p>
            Je déclare avoir lu et accepté les{" "}
            <a href="/cg" target="_blank">
              conditions générales
            </a>{" "}
            de Legalify.ch.
          </p>
        </div>
      );
    }
  }

  returnSuccessUrl = () => {
    let str1 = "https://legalify.ch/payment-success";
    let str2 = "?type=" + this.getCheckoutBundle().type;
    let str3 = "&date=" + encodeURIComponent(this.state.dateObj);
    let str4 = "&phone=" + encodeURIComponent(this.state.phone);
    let str5 = "&email=" + this.state.mail;
    let str6 = "&prestationName=" + encodeURIComponent(this.props.title);
    let str7 =
      this.props.prest === "abo"
        ? "&aboStart=" + this.getCheckoutBundle().aboStart
        : "";
    let str8 =
      this.props.prest === "societe"
        ? "&associates=" +
          this.getCheckoutBundle().associates +
          "&capital=" +
          this.getCheckoutBundle().capital +
          "&canton=" +
          this.getCheckoutBundle().canton
        : "";
    let url = str1 + str2 + str3 + str4 + str5 + str6 + str7 + str8;
    return url;
  };

  getPriceString() {
    if (this.props.prest === "societe") {
      if (this.getSocietePrice() === "-") {
        return "-";
      } else {
        return "CHF " + this.getSocietePrice() + ".-";
      }
    } else {
      if (this.state.price === "-") {
        return "-";
      } else {
        return "CHF " + this.state.price + ".-";
      }
    }
  }

  // renders confirmation widget
  confirmationWidget() {
    return (
      <div id="confirmation">
        <div id="widget">
          <h3>Votre Commande:</h3>
          <ul>
            <li>
              <span className="field">
                {this.state.checked ? "Nom/Prénom :" : "Raison Sociale :"}
              </span>{" "}
              {this.state.name === "" ? "-" : this.state.name}
            </li>
            <li>
              <span className="field">E-mail :</span>{" "}
              {this.state.mail === "" ? "-" : this.state.mail}
            </li>
            <li>
              <span className="field">Téléphone :</span>{" "}
              {this.state.phone === "" ? "-" : this.state.phone}
            </li>
            {this.props.prest === "meeting" ? (
              <li>
                <span className="field">Date et heure :</span>{" "}
                {this.state.selectedTile === -1 || this.state.dateObj === null
                  ? "-"
                  : this.returnFullDateFr() +
                    " à " +
                    this.state.dateObj.getHours() +
                    "h" +
                    this.state.dateObj.getMinutes() +
                    (this.state.dateObj.getMinutes() === 0 ? "0" : "")}
              </li>
            ) : (
              ""
            )}
            {this.props.prest === "abo" ? (
              <li>
                <span className="field">Date de début :</span>{" "}
                {this.state.startDate}
              </li>
            ) : (
              ""
            )}
          </ul>
          <div id="total">Total à payer : {this.getPriceString()}</div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div id="checkout-form">
        {this.state.showLightboxOverlay ? (
          <div className="lightbox-bg lightbox-bg-in">
            <Lightbox
              transactionId={this.state.prestationId}
              uppWebResponseMethod={"GET"}
              production={true}
            />
          </div>
        ) : null}
        {this.progressBar()}
        {this.paymentConfirmation()}
        <div id="form-holder" className={this.state.step === 3 ? "hidden" : ""}>
          <div id="inputs">
            <div id="back-button-holder">
              <button
                id="back"
                onClick={this.lastStep}
                className={this.state.step > 0 ? "" : "hidden"}
              >
                ← Retour
              </button>
            </div>

            <h3>
              {this.state.step === 0
                ? "1. Informations"
                : this.state.step === 1
                ? this.getSecondTitle()
                : this.state.step === 2
                ? this.props.prest === "contract"
                  ? "2. Paiement"
                  : "3. Paiement"
                : "Merci"}
            </h3>
            <div id="inputs-modular">
              {this.clientInformation()}
              {this.specInformation()}
              {this.paymentInformation()}
            </div>
          </div>
          {this.confirmationWidget()}
        </div>
        <button
          onClick={this.nextStep}
          id="next"
          className={this.state.step === 3 ? "hidden" : ""}
        >
          {this.state.step === 2
            ? "Payer en ligne"
            : this.props.prest === "devis"
            ? "Envoyer ma demande"
            : "Suivant"}
          <img src={arrow}></img>
        </button>
        <div id="error-prompt">{this.state.prompt}</div>
      </div>
    );
  }
}

export default Checkout;
